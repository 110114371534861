.dashboardcontent3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 100px 0;
}

.dashboardcontent3 > div {
  width: 60%;
  text-transform: capitalize;
  font-weight: bold;
  box-shadow: -2px 2px 5px 2px silver;
  border-radius: 10px;
  padding: 4em;
  margin-top: 2em;
}
