.sportshoes {
  display: flex;
  justify-content: center;
  margin: 5em auto;
}
.sportshoes > div {
  width: 95%;
  margin-left: 2.3em !important;
}

.sportshoes img {
  width: 95%;
  object-fit: cover;
}

.sportshoes h2 {
  font-size: 2em;
  font-weight: bold;
  padding: 10px 0;
  margin-top: 1.7em;
}
.sportshoes p {
  font-size: 1em;
  color: dimgray;
  padding-bottom: 20px;
}
.sportshoes a {
  padding: 0.7em 2.8em;
}

@media screen and (min-width: 768px) {
  .sportshoes h2 {
    font-size: 3.5em;
  }
  .sportshoes p {
    font-size: 1.5em;
  }
  .sportshoes a {
    padding: 1.5em 3.2em;
  }
}
