.team > div:nth-of-type(1) {
  text-align: center;
  margin: 7em 0;
}
.team > div:nth-of-type(1) > h3 {
  text-transform: capitalize;
  font-size: 3em;
  font-weight: bold;
}

.team > div:nth-of-type(1) > p {
  color: dimgray;
  margin: 1.7em 0;
  padding: 0 4em;
}

.team .containerteam {
  text-align: center;
  padding: 0 2em;
  margin-bottom: 7em;
}

.team .containerteam > div {
  padding: 1.5em;
}

.team .containerteam img {
  margin-bottom: 20px;
  width: 95%;
}
.team .containerteam > div span {
  font-size: 0.7em;
  color: dimgray;
}
