.parent-slide {
  width: 100%;
  height: 85vh;
  background-color: #1c6dbe;
  transition: 0.7s;
  margin-top: 70px;
}
.slides {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 90vh;
  padding: 0 1em;
}
.parent-slide .slider-text > h2 {
  font-size: 2em !important;
  font-weight: bold;
  padding: 20px 0;
  color: white;
  margin-top: 0.2em;
  text-transform: uppercase;
  transform: translateX(-100px);
}

.title {
  transform: translateX(-50px);
  transition: 1s;
}
.parent-slide .slider-text > p {
  font-size: 1em;
  margin-top: -2em;
  padding-bottom: 30px;
  color: white;
  margin-left: 1.8em;
}
.text {
  transform: translatex(-1px);
  transition: 1s;
}

.parent-slide .slider-text > a {
  color: white;
  font-size: 1em;
  padding: 10px 20px;
  border: 2px solid white;
  transition: 0.7s;
  margin-left: 2.5em;
  margin-top: 2em;
}
.parent-slide .slider-text > a:hover {
  background-color: crimson;
}
.parent-slide img {
  width: 60%;
  height: 35vh;
  object-fit: cover;
  background-color: antiquewhite;
  border-radius: 50%;
  margin-top: -2em;
}
.swiper-button-next,
.swiper-button-prev {
  top: 85% !important;
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  padding: 10px 18px;
  display: none;
}
.swiper-button-prev {
  left: 70%;
}
.swiper-button-next {
  right: 10%;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 1.2em;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  width: 100px;
  margin-left: 30px;
  margin-bottom: 10px;
  display: none;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  transform: translateY(-4em);
  margin-left: 2.7em;
}

/*  برای کاستومایز کردن paginationباید کلاس مربوط به خودش را تغییر داد*/
/* برای از بین بردن پس زمینه مشکی پجیکیشن باید وارد فایل موردنظر شوید و پس زمینه مشکی را خنثی کنید و از طریق ای اسپکت نام کلاس و فایل را پیدا کنید*/
.swiper-pagination-bullet-active {
  /* background-color: white; */
  border: 2px solid white;
}
.swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  border: 2px solid white;
  border-radius: 50%;
}
@media screen and (max-width: 767px) {
  .parent-slide .slider-text > h2 {
    font-size: 2em !important;
    width: 100%;
    transform: translateX(-10px);
    text-align: center;
  }
  .parent-slide .slider-text > p {
    text-align: center;
    margin-left: 0;
  }
  .parent-slide .slider-text > a {
    display: flex;
    justify-content: center;
    width: 150px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 768px) {
  .parent-slide {
    margin-top: -1em;
  }
  .parent-slide .slider-text > h2 {
    font-size: 3em !important;
    width: 100%;
    transform: translateX(-30px);
  }

  .parent-slide .slider-text > p {
    font-size: 1.5em;
    padding-bottom: 30px;
    margin-top: -2em;
    margin-left: 0;
  }
  .parent-slide .slider-text > a {
    margin-left: 0;
    margin-top: 0;
  }
  .parent-slide img {
    width: 40%;
    margin-top: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: block;
    top: 90%;
  }
  .swiper-button-prev {
    left: 75%;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    margin-bottom: 30px;
    display: block;
  }
}
@media screen and (min-width: 992px) {
  .parent-slide {
    margin-top: 0;
  }
  .parent-slide .slider-text > h2 {
    font-size: 4.7em !important;
    width: auto;
    transform: translateX(-60px);
  }
  .parent-slide .slider-text > p {
    font-size: 1em;
    margin-top: -9em;
    margin-left: 0;
  }
  .parent-slide .slider-text > a {
    font-size: 1.5em;
    padding: 20px 30px;
  }
  .parent-slide img {
    width: 37%;
    height: 55vh;
  }
  .swiper-button-prev {
    left: 81%;
  }
}
