.headerpage {
  margin-bottom: 1.5em;
}

.singleproduct {
  display: flex;
  justify-content: center;
  align-items: center;
}
.singleproduct > div {
  width: 97%;
  margin: 4em 0;
}
.singleproduct > div > div:nth-of-type(1) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.singleproduct > div > div:nth-of-type(1) > figure {
  border: 2px solid rgba(192, 192, 192, 0.555);
  width: 90%;
  height: 90%;
  border-radius: 10px;
}

.singleproduct figure > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  padding: 5.5em;
  border-radius: 10px;
}

.singleproduct .thumb {
  display: flex;
  justify-content: center;
}
.singleproduct .thumb > img {
  width: 100px;
  height: 100px;
  border: 1px solid silver;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
}

.titlepoduct {
  text-align: center;
  padding: 2em 0;
  font-weight: bold;
}
.priceproduct {
  text-align: center;
}
.addcart {
  margin-left: 1.5em;
}
.singleproduct > div > div:nth-of-type(2) {
  padding: 3em 2.2em;
  margin-top: -2.5em;
}
.singleproduct p:nth-of-type(1) {
  font-weight: bold;
  font-size: 1.7em;
  padding: 1.7em;
}
.singleproduct p:nth-of-type(2) {
  padding: 1.7em 1.7em 1.7em 1.7em;
  border-top: 1px solid silver;
}
.title {
  margin: 2.5em 0;
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
}
.parent-relative {
  display: flex;
  justify-content: center;
  margin-left: 1.5em;
}
.relative {
  margin-top: 1.5em;
  margin-bottom: 4.3em;
}

.featureimage {
  box-shadow: 0 0 5px silver;
  border-radius: 1.5em;
  width: 93%;
  transition: 0.7s;
  margin-bottom: 20px;
}

.featureimage:hover {
  box-shadow: 0 0 5px rgb(143, 141, 141);
}

@media screen and (min-width: 992px) {
  .titlepoduct {
    text-align: left;
    padding: 0 0;
  }
  .priceproduct {
    text-align: left;
  }
}
