.errorpage {
  width: 100%;
  height: 100vh;
  background-color: rgb(48, 33, 88);
  display: flex;
  justify-content: center;
  align-items: center;
}
.errorpage > p {
  color: white;
  font-size: 15em;
}
