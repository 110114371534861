* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  list-style: none;
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
  max-width: 100%;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
a {
  text-decoration: none;
}
button {
  border: none;
  outline: none;
}

.headerpage {
  position: relative;
  padding: 5em 0;
  width: 100%;
}
.headerpage img {
  width: 100%;
}
.headerpagetitle {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1em;
  text-transform: uppercase;
}
.linkbtnred {
  border: none;
  outline: none;
  color: white;
  background-color: red;
  border: 2px solid red;
  padding: 7px 20px;
  margin: 17px 0;
  border-radius: 5px;
  transition: 0.7s;
}

.linkbtnred:hover {
  background-color: white;
  color: crimson;
}
@media screen and (min-width: 768px) {
  .headerpagetitle {
    font-size: 3em;
  }
}
.dashboardcontent {
  width: 60%;
  box-shadow: 0 0 5px silver;
  border-radius: 10px;
  color: rgb(87, 85, 85);
  padding: 50px;
  margin: 100px 20px;
}
@media screen and (max-width: 992px) {
  .dashboardcontent {
    width: 90%;
    margin: 10px 40px 70px 40px;
  }
}
