.backgroundcart {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.466);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
}

.cart {
  background-color: rgb(255, 251, 251);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  z-index: 1100;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: 0.6s;
  width: 250px;
}
.cart .titlecard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 10px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.hidecart {
  right: -100%;
}
.cart .titlecard > span:nth-of-type(2) {
  cursor: pointer;
}
.cart > .emptycart {
  color: crimson;
  background-color: rgb(228, 222, 222);
  text-align: center;
  padding: 10px 0;
  font-size: 0.8em;
  text-transform: capitalize;
}
.cart > .showcart {
  color: rgb(34, 33, 33);
  background-color: rgb(228, 222, 222);
  text-align: center;
  padding: 10px 0;
  font-size: 0.8em;
  text-transform: capitalize;
}
.contentcart {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid silver;
  padding: 5px 0;
  transition-delay: 0.5s;
}

.contentcart button {
  border: none;
  outline: none;
  padding: 5px 10px;
  background-color: rgb(26, 24, 24);
  color: white;
  font-size: 0.8em;
  margin: 20px 5px;
  width: 30px;
}
.contentcart > div span:nth-of-type(1) {
  font-size: 0.7em;
  color: dimgray;
  margin-top: 10px;
}
.contentcart > div > div {
  display: flex;
  align-items: center;
}
.contentcart > div > div > span:nth-of-type(1) {
  color: rgb(22, 21, 21);
  font-size: 0.8em;
  margin: 15px 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentcart .count {
  display: flex;
  width: 80px;
}

.contentcart > div > div > .price {
  color: rgb(24, 21, 21);
  padding: 25px 0;
  font-size: 0.9em;
}
.contentcart > div > div > span:nth-of-type(3) {
  color: rgb(24, 22, 22);
}
.contentcart .productcart {
  margin: 10px 40px;
}
.contentcart .productcart img {
  width: 90px;
  height: 90px;
  margin: 10px;
}
.contentcart .productcart span {
  font-size: 0.8em !important;
  display: block;
}
.cart .total {
  text-align: center;
  padding: 10px;
  color: rgb(17, 13, 13);
  margin: 10px 0;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
}
.cart .checkout {
  background-color: black;
  color: white;
  width: 100%;
  height: 50px;
  transition: 0.7s;
}

.cart .checkout:hover {
  background-color: rgb(206, 19, 19);
}
